








import { Component, Vue } from "vue-property-decorator";
import FormularioComponent from "@/components/FormularioEscolar/FormularioComponent.vue";

@Component({
  components: {
    FormularioComponent
  }
})
export default class FormularioView extends Vue {}
