







































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";

interface reservaData {
  rut: string;
  dia: string;
  hora: string;
  nombre: string;
  mesa: string;
}

interface dataIngreso {
  rut_apoderado: string;
  nombre_apoderado: string;
  apellido_paterno_apoderado: string;
  apellido_materno_apoderado: string;
  fecha_nacimiento_apoderado: Date | null;
  direccion: string;
  sector: string;
  correo_apoderado: string;
  telefono_apoderado: string;
  nombre_alumno: string;
  apellido_paterno_alumno: string;
  apellido_materno_alumno: string;
  rut_alumno: string;
  establecimiento: string;
  nivel_educacional: string;
}

interface selectType {
  value: number | null;
  label: string | null;
}

@Component({
  components: {
    MensajeAlEnviarForm: () =>
      import(
        /* webpackPrefetch: true */ "@/components/FormularioEscolar/MensajeAlEnviarForm.vue"
      ),
  },
})
export default class FormularioComponent extends Mixins(FormValidator) {
  private formattedRut = "";
  private formattedRutMenor = "";
  private validRut = true;
  private errorType = "";
  private step = "LANDING";
  private apiData: any = null;

  private dataForm: dataIngreso = {
    rut_apoderado: "",
    nombre_apoderado: "",
    apellido_paterno_apoderado: "",
    apellido_materno_apoderado: "",
    fecha_nacimiento_apoderado: null,
    direccion: "",
    sector: "",
    correo_apoderado: "",
    telefono_apoderado: "",
    nombre_alumno: "",
    apellido_paterno_alumno: "",
    apellido_materno_alumno: "",
    rut_alumno: "",
    establecimiento: "",
    nivel_educacional: "",
  };

  private datosReserva: reservaData = {
    rut: "",
    dia: "",
    hora: "",
    nombre: "",
    mesa: "",
  };

  private rutEnRsh = false;

  private sectorOptions: selectType[] = [
    { value: 1, label: "SECTOR NORTE" },
    { value: 2, label: "SECTOR SUR" },
    { value: 3, label: "BELLOTO CENTRO–BELLOTO 2000" },
    { value: 4, label: "CENTRO" },
    { value: 5, label: "EL RETIRO-EL SOL" },
    { value: 6, label: "TENIENTE SERRANO" },
    { value: 7, label: "VALENCIA" },
    { value: 8, label: "POMPEYA" },
    { value: 9, label: "LOS PINOS" },
    { value: 10, label: "RETIRO" },
    { value: 11, label: "RURAL" },
    { value: 12, label: "OTRO" },
  ];
  private dataSector: selectType = {
    value: null,
    label: " ",
  };
  private nivelOptions: selectType[] = [
    { value: 3, label: "BÁSICA" },
    { value: 4, label: "INCLUSIVA BÁSICA" },
    { value: 5, label: "MEDIA" },
    { value: 6, label: "SUPERIOR" },
    { value: 7, label: "INCLUSIVA LABORAL" },
  ];

  private dataNivel: selectType = {
    value: null,
    label: " ",
  };
  private formattedFecha = "";
  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
  };
  private carnet: File | null = null;
  private acreditacion: File | null = null;
  private invalidFileCarnet: boolean = false;
  private invalidFileAcreditacion: boolean = false;

  @Watch("formattedRut")
  private formatRut(newValue: any) {
    newValue = newValue
      .replace(/\./g, "")
      .replace(/-/g, "")
      .toUpperCase()
      .trim()
      .replaceAll(/\s/g, "");
    this.dataForm.rut_apoderado = this.rutSanitize(newValue);
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  @Watch("formattedRutMenor")
  private formatRutMenor(newValue: any) {
    newValue = newValue
      .replace(/\./g, "")
      .replace(/-/g, "")
      .toUpperCase()
      .trim()
      .replaceAll(/\s/g, "");
    this.dataForm.rut_alumno = this.rutSanitize(newValue);
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRutMenor = `${rut}-${dv}`;
    } else {
      this.formattedRutMenor = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  @Watch("dataSector")
  private watchSector(newValue: any) {
    this.dataForm.sector = newValue.label;
  }

  @Watch("dataNivel")
  private watchNivel(newValue: any) {
    this.dataForm.nivel_educacional = newValue.label;
  }

  @Watch("formattedFecha")
  private edadWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    }
    let diff;
    if (birthDate instanceof Date) {
      diff = now.getTime() - birthDate.getTime();
      this.dataForm.fecha_nacimiento_apoderado = birthDate;
    } else {
      diff = now.getTime() - new Date(birthDate).getTime();
      this.dataForm.fecha_nacimiento_apoderado = new Date(birthDate);
    }
  }
  private mounted() {}

  private validarRutRutEnRsh() {
    if (this.dataForm.rut_apoderado.replace(/\s/g, "") == "") {
      Notify.create({
        color: "negative",
        message: "Debe completar el Rut del adulto responsable",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (!this.validateRut(this.dataForm.rut_apoderado)) {
      Notify.create({
        color: "negative",
        message: "Debe ingresar un Rut válido",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("/rut/rsh?rut=" + this.dataForm.rut_apoderado)
      .then((res: any) => {
        this.dataForm.apellido_materno_apoderado = res.data[0].apellido_materno;
        this.dataForm.apellido_paterno_apoderado = res.data[0].apellido_paterno;
        this.dataForm.nombre_apoderado = res.data[0].nombre;
        this.$q.loading.hide();
        this.rutEnRsh = true;
      })
      .catch((err: any) => {
        if (err.response.data.message == "NO_REGISTRADO") {
          this.$q.loading.hide();
          // Error
          Notify.create({
            color: "negative",
            message: "El RUT no cumple con los requisitos mínimos (RSH hasta 70%)",
            icon: "report_problem",
            position: "top",
            timeout: 4000,
          });
          return;
        } else {
          this.$q.loading.hide();
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un error, intentelo nuevamente",
            icon: "report_problem",
            position: "top",
            timeout: 4000,
          });
          return;
        }
      });
  }

  private enviarForm() {
    if (this.dataForm.rut_apoderado.replace(/\s/g, "") == "") {
      Notify.create({
        color: "negative",
        message: "Debe completar el Rut del adulto responsable",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (!this.validateRut(this.dataForm.rut_apoderado)) {
      Notify.create({
        color: "negative",
        message: "Debe ingresar un Rut válido",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.dataForm.nombre_apoderado.replace(/\s/g, "") == "") {
      Notify.create({
        color: "negative",
        message: "Debe completar el Nombre del adulto responsable",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.dataForm.apellido_paterno_apoderado.replace(/\s/g, "") == "") {
      Notify.create({
        color: "negative",
        message: "Debe completar el Apellido Paterno del adulto responsable",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (
      this.dataForm.fecha_nacimiento_apoderado == null ||
      !this.checkFecha(this.dataForm.fecha_nacimiento_apoderado)
    ) {
      Notify.create({
        color: "negative",
        message:
          "Ingrese una fecha de nacimiento del adulto responsable válida",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.dataForm.direccion.replace(/\s/g, "") == "") {
      Notify.create({
        color: "negative",
        message: "Debe completar la dirección del adulto responsable",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (
      !this.dataForm.sector ||
      this.dataForm.sector.replace(/\s/g, "") == ""
    ) {
      Notify.create({
        color: "negative",
        message:
          "Debe seleccionar el sector de procedencia del adulto responsable",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.dataForm.correo_apoderado.replace(/\s/g, "") != "") {
      if (!this.validEmail(this.dataForm.correo_apoderado)) {
        Notify.create({
          color: "negative",
          message: "Debe ingresar un correo del adulto responsable válido",
          icon: "report_problem",
          position: "top",
          timeout: 2500,
        });
        return;
      }
    }

    if (this.dataForm.telefono_apoderado.replace(/\s/g, "") == "") {
      Notify.create({
        color: "negative",
        message: "Debe ingresar un teléfono del adulto responsable",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.dataForm.telefono_apoderado.length < 9) {
      Notify.create({
        color: "negative",
        message:
          "Debe ingresar un teléfono del adulto responsable válido (9 dígitos)",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.dataForm.rut_alumno.replace(/\s/g, "") == "") {
      Notify.create({
        color: "negative",
        message: "Debe completar el Rut del alumno(a)",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (!this.validateRut(this.dataForm.rut_alumno)) {
      Notify.create({
        color: "negative",
        message: "Debe ingresar un Rut válido",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.dataForm.nombre_alumno.replace(/\s/g, "") == "") {
      Notify.create({
        color: "negative",
        message: "Debe completar el nombre del alumno(a)",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.dataForm.apellido_paterno_alumno.replace(/\s/g, "") == "") {
      Notify.create({
        color: "negative",
        message: "Debe completar el apellido paterno del alumno(a)",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.dataForm.establecimiento.replace(/\s/g, "") == "") {
      Notify.create({
        color: "negative",
        message: "Debe completar el establecimiento del alumno(a)",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.dataForm.nivel_educacional.replace(/\s/g, "") == "") {
      Notify.create({
        color: "negative",
        message: "Debe seleccionar el nivel educacional del alumno(a)",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.carnet == null || this.invalidFileCarnet == true) {
      Notify.create({
        color: "negative",
        message:
          "Debe agregar una Cédula de identidad o certificado de nacimiento del alumno",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.acreditacion == null || this.invalidFileAcreditacion == true) {
      Notify.create({
        color: "negative",
        message:
          "Debe agregar un Comprobante de matrícula 2024 o certificado de alumno regular año 2024 válido",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    const data = new FormData();
    if (this.carnet) {
      const ciFile = this.carnet as Blob;
      data.append("carnet", ciFile);
    } else {
      const ciFile = new File(["file"], "Carnet") as Blob;
      data.append("carnet", ciFile);
    }
    if (this.acreditacion) {
      const acFile = this.acreditacion as Blob;
      data.append("acreditacion", acFile);
    } else {
      const acFile = new File(["file"], "Acreditación") as Blob;
      data.append("acreditacion", acFile);
    }
    for (const prop in this.dataForm) {
      if (this.dataForm.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.dataForm[prop] || this.dataForm[prop] === 0) {
          // @ts-ignore
          data.append(prop, this.dataForm[prop]);
        }
      }
    }
    if (this.dataForm.apellido_materno_apoderado == "") {
      data.append("apellido_materno_apoderado", "");
    }
    if (this.dataForm.apellido_materno_alumno == "") {
      data.append("apellido_materno_alumno", "");
    }
    if (this.dataForm.correo_apoderado == "") {
      data.append("correo_apoderado", "");
    }
    this.$q.loading.show({
      delay: 100, // ms
    });

    this.$axios
      .post("/postulacion/estudiante/2023", data)
      .then((res: any) => {
        this.$q.loading.hide();
        this.errorType = "SUCCESS";
      })
      .catch((err: any) => {
        if (err.response.data.message == "MENOR_REGISTRADO") {
          this.$q.loading.hide();
          // Error
          this.errorType = "OTRO";
          Notify.create({
            color: "negative",
            message: "El estudiante ingresado ya se encuentra registrado",
            icon: "report_problem",
            position: "top",
            timeout: 4000,
          });
          return;
        } else {
          this.$q.loading.hide();
          // Error
          this.errorType = "OTRO";
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un error, intentelo nuevamente",
            icon: "report_problem",
            position: "top",
            timeout: 4000,
          });
          return;
        }
      });
  }
  private checkFileCarnet(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
    if (target.files && target.files[0]) {
      for (const x in extensions) {
        if (target.files[0].type.includes(extensions[x])) {
          flag = true;
        }
      }
      if (target.files[0].size > 5 * 1024 * 1024 || !flag) {
        this.invalidFileCarnet = true;
        Notify.create({
          color: "negative",
          message: "El archivo sobrepasa el máximo permitido (5mb)",
          icon: "report_problem",
          position: "top",
          timeout: 4000,
        });
        return;
      } else if (target.files[0].size <= 0 || !flag) {
        this.invalidFileCarnet = true;
      } else {
        this.carnet = target.files[0];
        this.invalidFileCarnet = false;
      }
    }
  }
  private checkFileAcreditacion(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
    if (target.files && target.files[0]) {
      for (const x in extensions) {
        if (target.files[0].type.includes(extensions[x])) {
          flag = true;
        }
      }
      if (target.files[0].size > 5 * 1024 * 1024 || !flag) {
        this.invalidFileAcreditacion = true;
        Notify.create({
          color: "negative",
          message: "El archivo sobrepasa el máximo permitido (5mb)",
          icon: "report_problem",
          position: "top",
          timeout: 4000,
        });
        return;
      } else if (target.files[0].size <= 0 || !flag) {
        this.invalidFileAcreditacion = true;
      } else {
        this.acreditacion = target.files[0];
        this.invalidFileAcreditacion = false;
      }
    }
  }
  private rutSanitize(rut: string) {
    const temp = rut.replace(/\./g, "").replace(/-/g, "");
    const rutNumber = temp.substring(0, temp.length - 1);
    const dv = temp.substring(temp.length - 1);
    return `${rutNumber}-${dv}`;
  }
  private validEmail(email: any) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      this.validRut = false;
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        this.validRut = false;
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      this.validRut = true;
      return true;
    }
    this.validRut = false;
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
  private checkFecha(d: any) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
